<template>
  <div class="app-tab">
    <v-expansion-panels v-model="panel" focusable>
      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>
          <span><v-icon>android</v-icon> Serviços Android do Google - JSON</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <vue-json-pretty class="mt-4" :deep="8" :data.sync="google_services_json" highlight-mouseover-node show-line />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>
          <span><v-icon>mdi-apple</v-icon> Informações de Serviços IOS do Google - XML</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <vue-json-pretty class="mt-4" :deep="1" :data.sync="google_service_info" highlight-mouseover-node show-line />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-alert v-if="getProjectId" class="mt-4 mx-2" type="success" outlined dense>{{ 'Projeto: ' + getProjectId }}</v-alert>
    <v-alert v-else class="mt-4 mx-2" type="error" outlined dense>App ainda não enviado ao Firebase</v-alert>
    <v-card-actions class="pt-0">
      <v-btn width="260" :disabled="loading" class="my-3" color="primary" outlined @click="triggerFirebase"
        ><v-icon>mdi-send</v-icon> Criar App no Firebase</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
import { MUTATION_CREATE_FIREBASE } from '@/modules/accounts/graphql'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    VueJsonPretty: () => import('vue-json-pretty')
  },
  props: {
    app: Object
  },
  data: () => ({
    panel: [],
    loading: false,
    accountId: '',
    google_services_json: {},
    google_service_info: ''
  }),
  computed: {
    getProjectId() {
      return this.app?.metadata?.android?.google_services_json?.project_info?.project_id || false
    }
  },
  mounted() {
    this.setValues(this.app)
  },
  methods: {
    setValues(app) {
      this.accountId = this.$route.params.id
      this.google_service_info = app?.metadata?.ios?.google_service_info
      this.google_services_json = app?.metadata?.android?.google_services_json
    },
    async triggerFirebase() {
      this.loading = true

      try {
        const res = await this.$apollo.mutate({
          mutation: MUTATION_CREATE_FIREBASE,
          variables: {
            account_id: this.accountId
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        const accountName = res?.data?.triggerFirebase?.name
        this.$snackbar({ message: `Apps para o cliente "${accountName}" criados no Firebase com sucesso`, snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: `Falha ao realizar trigger do Firebase: ${err?.message || 'Sem detalhes do erro'}`, snackbarColor: '#F44336' })
      }

      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-tab {
  margin-top: 20px;
}

.v-icon {
  margin-right: 8px;
}
</style>
